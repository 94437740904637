<template>
  <div>

    <Navbar
    ></Navbar> 

    <GenericHeader :title="$t('view.import.header')"></GenericHeader>


  </div>
</template>

<script>

import Navbar from '@/components/Navbar.vue'
import GenericHeader from '@/components/GenericHeader.vue'

import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  title() {
    return this.$t('view.import.name')
  },
  components: {
    Navbar,
    GenericHeader,
  },
  methods: {
    ...mapActions([
    ]),
  },

  computed: {
    ...mapState({
      isDarkTheme: (state) => state.settings.isDarkTheme,
    }),

    ...mapGetters([
    ])
  },
}

</script>